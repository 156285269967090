import { ArrowDropUp } from "@mui/icons-material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

import { Box, Button } from "@mui/material";
import clsx from "clsx";
import { useMemo, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { Link } from "react-router-dom";

import InventoryItemIcon from "../InventoryItemIcon";

import { useAppSelector } from "../../app/hooks";
import config from "../../config/config";
import { Market } from "../../constants/enum/market";
import { getInventoryItemName } from "../../helper/inventory";
import useWallet from "../../hook/useWallet";
import { MarketListedItem } from "../../types/MarketListedItem";
import { convertDateTime, toUSDFormat } from "../../utils/utils";

import useCurrentTime from "../../hook/useCurrentTime";
import useStyles from "./index.styles";

interface MarketItemListProps {
  setOpenMarketAuctionPopup: React.Dispatch<React.SetStateAction<boolean>>;
  setOpenTokenListPopup: React.Dispatch<React.SetStateAction<boolean>>;
  setActionItem: React.Dispatch<
    React.SetStateAction<MarketListedItem | undefined>
  >;
  setPurchasingType: React.Dispatch<
    React.SetStateAction<Market.ListType | undefined>
  >;
  handleCancelListing: (item: MarketListedItem) => void;
  handleCompleteAuction: (item: MarketListedItem) => void;

  itemPerPage: number;
  currentPage: number;
  setCurrentPage: React.Dispatch<React.SetStateAction<number>>;
  setShowingBids: React.Dispatch<React.SetStateAction<boolean>>;

  itemSortBy: Market.SortBy;
  setItemSortBy: React.Dispatch<React.SetStateAction<Market.SortBy>>;

  itemSortDirection: boolean;
  setItemSortDirection: React.Dispatch<React.SetStateAction<boolean>>;
}

const MarketItemList: React.FC<MarketItemListProps> = ({
  setOpenMarketAuctionPopup,
  setOpenTokenListPopup,
  setActionItem,
  setPurchasingType,
  handleCancelListing,
  handleCompleteAuction,
  currentPage,
  setCurrentPage,
  itemPerPage,
  setShowingBids,
  itemSortBy,
  setItemSortBy,
  itemSortDirection,
  setItemSortDirection,
}) => {
  const { classes } = useStyles();
  const { account } = useWallet();
  const currentTime = useCurrentTime();

  const profileNames = useAppSelector((state) => state.profile.profileNames);
  const { listedItems, totalListedItems } = useAppSelector(
    (state) => state.market
  );

  const [sortByType, setSortByType] = useState(false);
  const [sortByUSD, setSortByUSD] = useState(false);
  const [sortByRemainTime, setSortByRemainTime] = useState(false);

  const parsedItem = useMemo(() => {
    return listedItems.map((item) => {
      return {
        ...item,
        currency:
          item.listingInfo.token === config.mafiaAddress
            ? "MAFIA"
            : config.chainSymbol,
      };
    });
  }, [listedItems]);

  const renderActionButton = (item: (typeof listedItems)[0]) => {
    const isSeller =
      account.toLowerCase() === item.listingInfo.seller.toLowerCase();

    const isExpired = item.listingInfo.expiresAt <= new Date().getTime() / 1000;

    if (isSeller) {
      if (isExpired) {
        if (item.listingInfo.listingType === Market.ListType.AUCTION) {
          return item.listingInfo.currentPrice !==
            item.listingInfo.startingPrice ? (
            <Button
              className={classes.buyButton}
              onClick={(event) => {
                event.stopPropagation(); // Prevent parent onClick from firing
                setActionItem(item);
                handleCompleteAuction(item);
              }}
            >
              Complete
            </Button>
          ) : (
            <Button
              className={classes.cancelButton}
              onClick={(event) => {
                event.stopPropagation(); // Prevent parent onClick from firing
                setActionItem(item);
                handleCancelListing(item);
              }}
            >
              Cancel
            </Button>
          );
        } else {
          return (
            <Button
              className={classes.cancelButton}
              onClick={(event) => {
                event.stopPropagation(); // Prevent parent onClick from firing
                setActionItem(item);
                handleCancelListing(item);
              }}
            >
              Cancel
            </Button>
          );
        }
      } else {
        if (item.listingInfo.listingType === Market.ListType.AUCTION) {
          return (
            <Button
              className={clsx(classes.cancelButton, classes.viewBidsButton)}
              onClick={(event) => {
                event.stopPropagation(); // Prevent parent onClick from firing
                setActionItem(item);
                setShowingBids(true);
                setOpenMarketAuctionPopup(true);
              }}
            >
              View Bids
            </Button>
          );
        } else {
          return <Box className={classes.listingStatusNormal}>Listed</Box>;
        }
      }
    } else {
      if (isExpired) {
        if (item.listingInfo.listingType === Market.ListType.AUCTION) {
          return item.listingInfo.currentPrice !==
            item.listingInfo.startingPrice ? (
            <Button
              className={classes.buyButton}
              onClick={(event) => {
                event.stopPropagation(); // Prevent parent onClick from firing
                setActionItem(item);
                handleCompleteAuction(item);
              }}
            >
              Complete
            </Button>
          ) : (
            <Button
              className={classes.cancelButton}
              onClick={(event) => {
                event.stopPropagation(); // Prevent parent onClick from firing
                setActionItem(item);
                handleCancelListing(item);
              }}
            >
              Cancel
            </Button>
          );
        } else {
          return (
            <Button
              className={classes.cancelButton}
              onClick={(event) => {
                event.stopPropagation(); // Prevent parent onClick from firing
                setActionItem(item);
                handleCancelListing(item);
              }}
            >
              Cancel
            </Button>
          );
        }
      } else {
        return item.listingInfo.listingType === Market.ListType.FIXED ? (
          <Button
            className={classes.buyButton}
            onClick={(event) => {
              event.stopPropagation(); // Prevent parent onClick from firing
              setActionItem(item);
              setPurchasingType(Market.ListType.FIXED);
              setOpenTokenListPopup(true);
            }}
          >
            Buy Item
          </Button>
        ) : (
          <Button
            className={classes.bidButton}
            onClick={(event) => {
              event.stopPropagation(); // Prevent parent onClick from firing
              setActionItem(item);
              setShowingBids(false);
              setPurchasingType(Market.ListType.AUCTION);
              setOpenMarketAuctionPopup(true);
            }}
          >
            Make bid
          </Button>
        );
      }
    }
  };

  const handleNext = () => {
    setCurrentPage(currentPage + 1);
  };

  const handleSortByType = () => {
    setSortByType((prev) => !prev);
    setCurrentPage(1);
    setItemSortDirection((prev) => !prev);
    setItemSortBy("Type");
  };

  const handleSortByUSD = () => {
    setSortByUSD((prev) => !prev);
    setCurrentPage(1);
    setItemSortDirection((prev) => !prev);
    setItemSortBy("USD");
  };

  const handleSortByTime = () => {
    setSortByRemainTime((prev) => !prev);

    setCurrentPage(1);
    setItemSortDirection((prev) => !prev);
    setItemSortBy("Time");
  };

  const handleProfileClick = (profileLink: string) => {
    window.open(profileLink, "_self");
  };

  return (
    <Box className={classes.container}>
      <Box className={classes.list}>
        <Box className={classes.listHeader}>
          <Box>Item</Box>
          <Box>Seller</Box>
          <Box className={classes.clickingOrder} onClick={handleSortByType}>
            Type
            {sortByType ? <ArrowDropDownIcon /> : <ArrowDropUp />}
          </Box>

          <Box className={classes.clickingOrder}>Price/Bid</Box>

          <Box className={classes.clickingOrder} onClick={handleSortByUSD}>
            USD
            {sortByUSD ? <ArrowDropDownIcon /> : <ArrowDropUp />}
          </Box>

          <Box>Currency</Box>

          <Box>Action</Box>

          <Box className={classes.clickingOrder} onClick={handleSortByTime}>
            Time Left
            {sortByRemainTime ? <ArrowDropDownIcon /> : <ArrowDropUp />}
          </Box>
        </Box>
        <Box className={classes.listBody} id="scrollable-div">
          <InfiniteScroll
            dataLength={parsedItem.length}
            hasMore={parsedItem.length !== totalListedItems}
            next={handleNext}
            scrollableTarget="scrollable-div"
            loader={<Box className={classes.loading}>Loading...</Box>}
            className={classes.scrollContainer}
          >
            {parsedItem.map((item, index) => {
              const profile = profileNames.find(
                (profile, index) =>
                  profile.address?.toLowerCase() ===
                  item.listingInfo.seller.toLowerCase()
              );

              const itemName = getInventoryItemName(
                item.categoryId,
                item.typeId,
                item.cityId
              );

              return (
                <Box
                  className={classes.listItem}
                  onClick={() => {
                    window.location.href = `/market/item/${
                      item.categoryId * 10 + item.typeId
                    }`;
                  }}
                  key={index}
                >
                  <Box className={classes.name}>
                    <InventoryItemIcon
                      categoryId={item.categoryId}
                      typeId={item.typeId}
                      className={classes.itemIcon}
                    />
                    {itemName}
                  </Box>

                  <Box
                    onClick={(event) => {
                      event.stopPropagation(); // Prevent parent onClick from firing
                      handleProfileClick(`/profile/${profile?.name}`);
                    }}
                    className={classes.link}
                  >
                    <Box className={classes.link}>{profile?.name}</Box>
                  </Box>

                  <Box>
                    {item.listingInfo.listingType === Market.ListType.FIXED
                      ? "Fixed"
                      : "Auction"}
                  </Box>

                  {item.listingInfo.listingType === Market.ListType.FIXED ? (
                    <Box className={classes.usdLabel}>
                      ${toUSDFormat(item.listingInfo.startingPrice)}
                    </Box>
                  ) : (
                    <Box className={classes.usdLabel}>{item.bidCount} Bids</Box>
                  )}

                  <Box>${toUSDFormat(item.listingInfo.currentPrice)}</Box>

                  <Box className={classes.currency}>
                    <Box
                      component="img"
                      className={classes.currencyIcon}
                      src={`/assets/tokens/${item.currency.toLocaleLowerCase()}.png`}
                    ></Box>
                    {item.currency}
                  </Box>

                  <Box>{renderActionButton(item)}</Box>

                  <Box>
                    {item.listingInfo.expiresAt - currentTime < 0 ? (
                      <Box className={classes.listingStatusExpired}>
                        Expired
                      </Box>
                    ) : (
                      <Box className={classes.listingStatusNormal}>
                        {convertDateTime(
                          item.listingInfo.expiresAt - currentTime
                        )}
                      </Box>
                    )}
                  </Box>
                </Box>
              );
            })}
          </InfiniteScroll>
        </Box>
      </Box>
    </Box>
  );
};

export default MarketItemList;
