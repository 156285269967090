import { Box, Skeleton, Typography } from "@mui/material";
import clsx from "clsx";
import { useEffect, useMemo, useState } from "react";

import Dot from "../Dot";
import InventoryItemActionPopup from "../InventoryItemActionPopup";
import Logo from "../Logo";

import { useAppSelector } from "../../app/hooks";
import { ItemCategoryInfoList } from "../../constants/const";
import { InventoryType } from "../../constants/enum/enum";
import { getInventoryItemInfo } from "../../helper/inventory";
import { ItemInfo } from "../../types/ItemInfo";
import { toUSDFormat } from "../../utils/utils";

import keyIcon from "../../assets/imgs/inventory/key.png";
import ogNFTIcon from "../../assets/imgs/inventory/OGNFTIMAGE.png";
import stMafiaIcon from "../../assets/imgs/profile/stlogo.png";
import bulletIcon from "../../assets/imgs/inventory/bulletitem.png";
import heartIcon from "../../assets/imgs/inventory/healthitem.png";

import useStyles from "./index.styles";

const ProfileInventoryMafia = ({
  isStaked,
  mafiaBalance,
}: {
  isStaked: boolean;
  mafiaBalance: number;
}) => {
  const { classes } = useStyles();

  return (
    <Box className={classes.inventoryItem}>
      <Box className={classes.inventoryItemBox}>
        {isStaked ? (
          <Box
            component="img"
            src={stMafiaIcon}
            className={clsx(
              classes.inventoryItemIcon,
              classes.inventoryMafiaIcon
            )}
          ></Box>
        ) : (
          <Logo
            classNames={clsx(
              classes.inventoryItemIcon,
              classes.inventoryMafiaIcon
            )}
          />
        )}
      </Box>
      <Typography className={classes.inventoryItemName}>
        {toUSDFormat(mafiaBalance)}
      </Typography>
    </Box>
  );
};

const ProfileInventoryBullets = () => {
  const { classes } = useStyles();

  return (
    <Box className={classes.inventoryItem}>
      <Box className={classes.inventoryItemBox}>
        <Box
          className={clsx(
            classes.inventoryItemIcon,
            classes.inventoryCrateIcon
          )}
          component="img"
          src={bulletIcon}
        ></Box>
      </Box>
      <Typography className={classes.inventoryItemName}>0</Typography>
    </Box>
  );
};

const ProfileInventoryHearts = () => {
  const { classes } = useStyles();

  return (
    <Box className={classes.inventoryItem}>
      <Box className={classes.inventoryItemBox}>
        <Box
          className={clsx(
            classes.inventoryItemIcon,
            classes.inventoryCrateIcon
          )}
          component="img"
          src={heartIcon}
        ></Box>
      </Box>
      <Typography className={classes.inventoryItemName}>0</Typography>
    </Box>
  );
};

const ProfileInventoryCrate = ({
  setTransferItemInfo,
}: {
  setTransferItemInfo: () => void;
}) => {
  const { classes } = useStyles();

  return (
    <Box className={classes.inventoryItem}>
      <Box className={classes.inventoryItemBox}>
        <Box
          className={clsx(
            classes.inventoryItemIcon,
            classes.inventoryCrateIcon
          )}
          component="img"
          src={keyIcon}
          onClick={setTransferItemInfo}
        ></Box>
      </Box>
      <Typography className={classes.inventoryItemName}>NFT Key</Typography>
    </Box>
  );
};

const ProfileInventoryOGNFT = ({
  setTransferItemInfo,
}: {
  setTransferItemInfo: () => void;
}) => {
  const { classes } = useStyles();

  return (
    <Box className={classes.inventoryItem}>
      <Box className={classes.inventoryItemBox}>
        <Box
          className={clsx(classes.inventoryItemIcon, classes.inventoryOGIcon)}
          component="img"
          src={ogNFTIcon}
          onClick={setTransferItemInfo}
        ></Box>
      </Box>
      <Typography className={classes.inventoryItemName}>OG NFT</Typography>
    </Box>
  );
};

const ProfileInventoryItem = ({
  item,
  setTransferItemInfo,
}: {
  setTransferItemInfo: () => void;
  item: ItemInfo;
}) => {
  const { classes } = useStyles();

  const itemInfo = getInventoryItemInfo(item.categoryId, item.typeId);

  return (
    <Box className={classes.inventoryItem}>
      <Box
        sx={{
          position: "relative",
        }}
        className={classes.inventoryItemBox}
      >
        <Box
          className={classes.inventoryItemIcon}
          component="img"
          src={itemInfo.itemIcon}
          onClick={setTransferItemInfo}
        ></Box>

        {(item.categoryId === 8 || item.categoryId === 9) && (
          <Logo
            sx={{
              position: "absolute",
              width: 16,
              height: 16,
              top: 4,
              left: 4,
            }}
          />
        )}
      </Box>

      <Typography className={classes.inventoryItemName}>
        {itemInfo.itemValue}
      </Typography>
    </Box>
  );
};

const ProfileInventoryItemSkeleton = () => {
  const { classes } = useStyles();

  return (
    <Box className={classes.inventoryItem}>
      <Skeleton
        height={56}
        width={62}
        variant="rounded"
        animation="wave"
        sx={{ bgcolor: "#ffffff1a" }}
      />

      <Skeleton
        height={24}
        width={62}
        variant="rounded"
        animation="wave"
        sx={{ bgcolor: "#ffffff1a" }}
      />
    </Box>
  );
};

const ProfileInventory = ({
  small,
  profileView,
  crateBalance,
  ogNFTBalance,
  inventoryType,
  isPrivate,
}: {
  small?: boolean;
  profileView?: boolean;
  crateBalance?: number;
  ogNFTBalance?: number;
  inventoryType?: InventoryType;
  isPrivate?: boolean;
}) => {
  const { classes } = useStyles();

  const { profile, myProfile, inventoryItems, isLoadingInventoryItems } =
    useAppSelector((state) => state.profile);

  const [inventoryWorth, setInventoryWorth] = useState(1000);
  const [openCrateOpeningPopup, setOpenCrateOpeningPopup] = useState(false);
  const [transferItemInfo, setTransferItemInfo] = useState<ItemInfo>({
    categoryId: -1,
    cityId: -1,
    typeId: 0,
    itemId: 0,
  });

  const randomItems = useMemo(() => {
    return [...new Array(16)].map((_, index) => {
      return {
        categoryId: Math.floor(Math.random() * 8),
        typeId: Math.floor(Math.random() * 8),
        cityId: -1,
        itemId: -1,
      } as ItemInfo;
    });
  }, []);

  useEffect(() => {
    const inventoryWorth = inventoryItems
      .map(
        (item) =>
          ItemCategoryInfoList[item.categoryId].inventoryWorth[item.typeId]
      )
      .reduce((a, b) => a + b, 0);

    setInventoryWorth(inventoryWorth);
  }, [inventoryItems]);

  const renderProfileInventory = () => {
    const renderItems = () => {
      return (
        <>
          {[...inventoryItems]
            .sort(
              (item1, item2) =>
                ItemCategoryInfoList[item2.categoryId].inventoryWorth[
                  item2.typeId
                ] -
                  ItemCategoryInfoList[item1.categoryId].inventoryWorth[
                    item1.typeId
                  ] || item1.categoryId - item2.categoryId
            )
            .map((item, index) => {
              return (
                <ProfileInventoryItem
                  setTransferItemInfo={() => {
                    setTransferItemInfo(item);
                    setOpenCrateOpeningPopup(true);
                  }}
                  item={item}
                  key={index}
                />
              );
            })}
        </>
      );
    };

    const renderNFTs = () => {
      return (
        <>
          <ProfileInventoryMafia
            isStaked={true}
            mafiaBalance={profile.stMafia || 0}
          />

          <ProfileInventoryMafia
            isStaked={false}
            mafiaBalance={profile.mafia || 0}
          />

          <ProfileInventoryBullets/>
          <ProfileInventoryHearts/>

          {[...new Array(ogNFTBalance)].map((item, index) => {
            return (
              <ProfileInventoryOGNFT
                setTransferItemInfo={() => {
                  const ogNFT: ItemInfo = {
                    categoryId: -2, // OG NFT
                    cityId: -1,
                    typeId: 0,
                    itemId: 0,
                  };
                  setTransferItemInfo(ogNFT);
                  setOpenCrateOpeningPopup(true);
                }}
                key={index}
              />
            );
          })}

          {[...new Array(crateBalance)].map((item, index) => {
            return (
              <ProfileInventoryCrate
                setTransferItemInfo={() => {
                  const crate: ItemInfo = {
                    categoryId: -1,
                    cityId: -1,
                    typeId: 0,
                    itemId: 0,
                  };
                  setTransferItemInfo(crate);
                  setOpenCrateOpeningPopup(true);
                }}
                key={index}
              />
            );
          })}
        </>
      );
    };

    const renderPrivateItems = () => {
      return randomItems.map((item, index) => {
        return (
          <ProfileInventoryItem
            setTransferItemInfo={() => {
              setTransferItemInfo(item);
              setOpenCrateOpeningPopup(true);
            }}
            item={item}
            key={index}
          />
        );
      });
    };

    return (
      <Box className={classes.inventoryCard}>
        <Box className={classes.cardHeader}>
          {inventoryType === "NFTs" ? (
            <>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "8px",
                }}
              >
                <Typography className={classes.cardHeaderTitle}>
                  Wallet Inventory
                </Typography>

                <Box className={classes.cardHeaderBadgeSafe}>
                  <Box>SAFE</Box>
                  <Dot width={10} color="#50b828" isShadow={true} />
                </Box>
              </Box>
              {/* <Typography className={classes.cardHeaderWorth}>
                {crateBalance} Key NFT, {ogNFTBalance} OG NFT
              </Typography> */}
            </>
          ) : inventoryType === "Items" ? (
            <>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "8px",
                }}
              >
                <Typography className={classes.cardHeaderTitle}>
                  Game Inventory
                </Typography>
                <Box className={classes.cardHeaderBadgeDanger}>
                  <Box>AT RISK</Box>
                  <Dot width={10} color="red" isShadow={true} />
                </Box>
              </Box>
              <Typography className={classes.cardHeaderWorth}>
                Estimated worth: {inventoryWorth}
              </Typography>
            </>
          ) : (
            <>
              <Typography className={classes.cardHeaderTitle}>
                Your Inventory
              </Typography>
              <Typography className={classes.cardHeaderWorth}>
                {crateBalance} Key NFT, {ogNFTBalance} OG NFT, Estimated worth:{" "}
                {inventoryWorth}
              </Typography>
            </>
          )}
        </Box>

        <Box
          className={
            isPrivate
              ? clsx(classes.cardList, classes.private)
              : classes.cardList
          }
        >
          {isLoadingInventoryItems ? (
            [...new Array(small ? 21 : 30)].map((item, index) => {
              return <ProfileInventoryItemSkeleton key={index} />;
            })
          ) : inventoryType === "Items" ? (
            isPrivate ? (
              renderPrivateItems()
            ) : (
              renderItems()
            )
          ) : inventoryType === "NFTs" ? (
            renderNFTs()
          ) : (
            <>
              {renderItems()}
              {renderNFTs()}
            </>
          )}
        </Box>
      </Box>
    );
  };

  return (
    <Box className={classes.body}>
      <Box
        className={classes.container}
        sx={{
          width: small ? "100%!important" : "920px",
        }}
      >
        {renderProfileInventory()}

        {isPrivate && (
          <Typography
            fontFamily="Philosopher"
            fontSize={20}
            color={"white"}
            fontWeight={700}
            sx={{
              position: "absolute",
              left: "50%",
              top: "50%",
              transform: "translate(-50%, -50%)",
            }}
          >
            Inventory Private
          </Typography>
        )}
      </Box>

      {(!profileView || (profileView && profile.id === myProfile.id)) && (
        <InventoryItemActionPopup
          itemInfo={transferItemInfo}
          openItemTransferPopup={openCrateOpeningPopup}
          setOpenCrateOpeningPopup={setOpenCrateOpeningPopup}
        />
      )}
    </Box>
  );
};

export default ProfileInventory;
