import { useGSAP } from "@gsap/react";
import CloseIcon from "@mui/icons-material/Close";
import { Box, Button, Dialog } from "@mui/material";
import clsx from "clsx";
import gsap from "gsap";
import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";

import { ItemCategoryInfoList } from "../../constants/const";
import { ItemInfo } from "../../types/ItemInfo";
import { toUSDFormat } from "../../utils/utils";

import crateIcon from "../../assets/imgs/inventory/crate.png";

import config from "../../config/config";
import useStyles from "./index.styles";

gsap.registerPlugin(useGSAP);

interface CrateOpeningPopupProps {
  handleOpenCrate: () => void;
  onEndAnimation: () => void;
  txHash: string;
  crateBalance: number;
  itemInfo: ItemInfo;
  openCrateOpeningPopup: boolean;
  setOpenCrateOpeningPopup: React.Dispatch<React.SetStateAction<boolean>>;
  setOpenTokenListPopup: React.Dispatch<React.SetStateAction<boolean>>;
}

const CrateOpeningPopup: React.FC<CrateOpeningPopupProps> = ({
  handleOpenCrate,
  onEndAnimation,
  txHash,
  crateBalance,
  itemInfo,
  openCrateOpeningPopup,
  setOpenCrateOpeningPopup,
  setOpenTokenListPopup,
}) => {
  const { classes } = useStyles();

  // State to manage the currently selected index, speed, and cycles
  const [currentIndex, setCurrentIndex] = useState(-1);
  const [speed, setSpeed] = useState(15); // initial speed in milliseconds
  const [cycles, setCycles] = useState(0); // tracks full cycles through the prize list
  const [animationStarted, setAnimationStarted] = useState(false);
  const [direction, setDirection] = useState(1); // 1 for increasing, -1 for decreasing

  const selectedCategory = ItemCategoryInfoList[itemInfo.categoryId];
  const maxIndex = ItemCategoryInfoList[itemInfo.categoryId].values.length - 1;
  const totalCycles = 10; // total number of cycles before stopping

  const tl = useRef<gsap.core.Timeline>();
  const winContentRef = useRef<HTMLElement>();

  useEffect(() => {
    if (!openCrateOpeningPopup) return;

    const timer = setTimeout(() => {
      setAnimationStarted(true);
    }, 500);

    return () => {
      clearTimeout(timer);
    };
  }, [openCrateOpeningPopup]);

  useEffect(() => {
    if (!animationStarted) return;

    const timer = setInterval(() => {
      setCurrentIndex((prevIndex) => {
        const nextIndex = prevIndex + direction;

        // Check if we need to reverse the direction
        if (nextIndex >= maxIndex) {
          setDirection(-1); // Switch to decreasing
          setCycles((prevCycles) => prevCycles + 1); // Increment cycle count
          return maxIndex; // Ensure it stays within bounds
        } else if (nextIndex <= 0) {
          setDirection(1); // Switch to increasing
          setCycles((prevCycles) => prevCycles + 1); // Increment cycle count
          return 0; // Ensure it stays within bounds
        }

        return nextIndex;
      });

      // Gradually slow down
      setSpeed((prevSpeed) => prevSpeed * 1.033);
    }, speed);

    // Stop condition based on cycles and specific index
    if (cycles > totalCycles && currentIndex === itemInfo.typeId) {
      clearInterval(timer);
      initPopup();
      onEndAnimation();

      startWinAnimation();
    }

    return () => clearInterval(timer);
  }, [animationStarted, currentIndex, speed, cycles]);

  const initPopup = () => {
    setAnimationStarted(false);
    setSpeed(15);
    setCycles(0);
  };

  const handleClose = () => {
    setOpenCrateOpeningPopup(false);
  };

  const handleOpenNewCrate = () => {
    handleClose();
    handleOpenCrate();
  };

  const handlePurchaseNewCrate = () => {
    handleClose();
    setOpenTokenListPopup(true);
  };

  const startWinAnimation = () => {
    tl.current = gsap.timeline();
    tl.current.to(".prizeListItem", { opacity: 0, delay: 1, stagger: 0.1 });
    if (!winContentRef.current) return;

    tl.current.fromTo(
      winContentRef.current,
      { opacity: 0, scale: 0 },
      { opacity: 1, scale: 1, ease: "bounce" }
    );
  };

  return (
    <Dialog
      open={openCrateOpeningPopup}
      onClose={handleClose}
      className={classes.body}
    >
      <Box className={classes.modalContent}>
        <Box className={classes.closeIconBody}>
          <Button onClick={handleClose}>
            <CloseIcon htmlColor="gray" />
          </Button>
        </Box>

        <Box className={classes.contentBody}>
          <Box className={classes.header}>
            <Box component="img" src={crateIcon}></Box>
          </Box>

          <Box className={classes.container}>
            {ItemCategoryInfoList[itemInfo.categoryId].values.map(
              (value, index) => {
                const icon =
                  ItemCategoryInfoList[itemInfo.categoryId].icons.length > 0
                    ? ItemCategoryInfoList[itemInfo.categoryId].icons[index]
                    : ItemCategoryInfoList[itemInfo.categoryId].icon;

                return (
                  <Box
                    key={index}
                    className={
                      index === currentIndex
                        ? clsx(
                            classes.prizeItem,
                            classes.selected,
                            "prizeListItem",
                            "selected"
                          )
                        : clsx(classes.prizeItem, "prizeListItem")
                    }
                  >
                    <Box>
                      {typeof value === "number"
                        ? selectedCategory.currency
                          ? `${selectedCategory.currency} ${toUSDFormat(value)}`
                          : `${toUSDFormat(value)}`
                        : value}
                    </Box>

                    <Box
                      component="img"
                      src={`/assets/imgs/inventory/${icon}`}
                    ></Box>
                  </Box>
                );
              }
            )}
          </Box>

          <Box className={classes.winContent} ref={winContentRef}>
            <Box className={classes.grateText}>Congratulations!</Box>

            <Box className={classes.prizeName}>
              {typeof selectedCategory.values[0] === "number"
                ? selectedCategory.name
                : selectedCategory.values[itemInfo.typeId]}
            </Box>
            <Box className={classes.prizeDetail}>
              {typeof selectedCategory.values[itemInfo.typeId] === "number"
                ? selectedCategory.currency
                  ? `${selectedCategory.currency} ${toUSDFormat(
                      selectedCategory.values[itemInfo.typeId]
                    )}`
                  : `${toUSDFormat(selectedCategory.values[itemInfo.typeId])}`
                : selectedCategory.values[itemInfo.typeId]}
            </Box>

            <Box
              className={classes.prizeIcon}
              component="img"
              src={`/assets/imgs/inventory/${
                selectedCategory.icons.length === 0
                  ? selectedCategory.icon
                  : selectedCategory.icons[itemInfo.typeId]
              }`}
            ></Box>

            <Box className={classes.description}>
              Has Been added to your inventory
            </Box>
            <Link
              className={classes.viewTxLink}
              to={`${config.explorerUrl}tx/${txHash}`}
              target="_blank"
            >
              View Transaction
            </Link>

            {crateBalance === 0 ? (
              <Button
                className={classes.openCreateButton}
                onClick={handlePurchaseNewCrate}
              >
                Purchase new Crate
              </Button>
            ) : (
              <Button
                className={classes.openCreateButton}
                onClick={handleOpenNewCrate}
              >
                Open New Crate
              </Button>
            )}
          </Box>
        </Box>
      </Box>
    </Dialog>
  );
};

export default CrateOpeningPopup;
