import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { SERVER_URL } from "./../config/config";

import { signMessage, transferErc1155 } from "../helper/contractFunctions";
import {
  getStockCounts,
  transferItem,
  unwrapKeyItem,
  unwrapMafiaItem,
  unwrapOGCrate,
  unwrapOGNFT,
  unwrapOGNFTItem,
  wrapOGCrate,
  wrapOGNFT,
} from "../helper/contractFunctions/MafiaInventory";
import {
  buyPremium,
  claimKeys,
  getUserInfo,
} from "../helper/contractFunctions/MafiaOGPremium";
import {
  approveTicketContract,
  boughtTicket,
  purchaseTicket,
  userTicketId,
} from "../helper/contractFunctions/MafiaProfileTicket";
import {
  crateBalanceOf,
  setCrateApprovalForAll,
} from "../helper/contractFunctions/OgCrate";
import {
  ogNFTBalance,
  setOGNFTApprovalForAll,
} from "../helper/contractFunctions/OGNFT";

import { Errors } from "../constants/errors";
import { UserInfo } from "../types/Contract/Premium/UserInfo";
import { ItemInfo } from "../types/ItemInfo";
import { ProfileNames } from "../types/ProfileName";
import { UserProfile } from "../types/UserProfile";
import { toastError } from "../utils/utils";

export interface ProfileState {
  profile: UserProfile;
  isLoadingProfile: boolean;

  myProfile: UserProfile;
  isLoadingMyProfile: boolean;
  myInventoryItems: ItemInfo[];
  isLoadingMyInventoryItems: boolean;

  inventoryItems: ItemInfo[];
  isLoadingInventoryItems: boolean;

  inventoryItemStocks: number[];
  isLoadingInventoryItemStocks: boolean;

  latestProfiles: UserProfile[];
  isLoadingLatestProfiles: boolean;

  profileNames: ProfileNames[];
  isLoadingProfileNames: boolean;

  premiumInfo: {
    userInfo: UserInfo;
    lastClaimedAt: number;
  };

  crateBalance: number;
  ogNFTBalance: number;

  isApprovingCrate: boolean;
  isApprovingOGNFT: boolean;

  isCreatingProfile: boolean;
  isTransferringItem: boolean;
  isTransferringCrate: boolean;

  isWrappingOgCrate: boolean;
  isUnwrappingOgCrate: boolean;
  isUnwrappingKeyItem: boolean;
  isUnwrappingMafiaItem: boolean;
  isUnwrappingOGNFTItem: boolean;

  isWrappingOGNFTAction: boolean;
  isUnwrappingOGNFTAction: boolean;

  isBuyingPremium: boolean;
  isClaimingKeys: boolean;

  isSigningProfileData: boolean;
  isUpdatingProfileDescription: boolean;
  isUpdatingProfileDisplaySetting: boolean;
}

const initialState: ProfileState = {
  profile: {},
  isLoadingProfile: false,

  myProfile: {},
  isLoadingMyProfile: false,
  myInventoryItems: [],
  isLoadingMyInventoryItems: false,

  inventoryItems: [],
  isLoadingInventoryItems: false,

  inventoryItemStocks: [],
  isLoadingInventoryItemStocks: false,

  latestProfiles: [],
  isLoadingLatestProfiles: false,

  profileNames: [],
  isLoadingProfileNames: false,

  crateBalance: 0,
  ogNFTBalance: 0,

  premiumInfo: {
    userInfo: {
      expireAt: 0,
      startedAt: 0,
    },
    lastClaimedAt: 0,
  },

  isApprovingCrate: false,
  isApprovingOGNFT: false,

  isCreatingProfile: false,
  isTransferringItem: false,
  isTransferringCrate: false,

  isWrappingOgCrate: false,
  isUnwrappingOgCrate: false,
  isWrappingOGNFTAction: false,
  isUnwrappingOGNFTAction: false,
  isUnwrappingOGNFTItem: false,

  isUnwrappingKeyItem: false,
  isUnwrappingMafiaItem: false,

  isBuyingPremium: false,
  isClaimingKeys: false,

  isSigningProfileData: false,
  isUpdatingProfileDescription: false,
  isUpdatingProfileDisplaySetting: false,
};

export const getUserPremiumInfo = createAsyncThunk(
  "profile/getUserPremiumInfo",
  async ({ account }: { account: string }) => {
    const { userInfo, lastClaimedAt } = await getUserInfo(account);

    return { userInfo, lastClaimedAt };
  }
);

export const getCrateBalance = createAsyncThunk(
  "profile/getCrateBalance",
  async ({ account }: { account: string }) => {
    const balance = await crateBalanceOf(account);

    return Number(balance);
  }
);

export const getOgNFTBalance = createAsyncThunk(
  "profile/getOgNFTBalance",
  async ({ account }: { account: string }) => {
    const balance = await ogNFTBalance(account);

    return Number(balance);
  }
);

export const approveProfileTicketAction = createAsyncThunk(
  "profile/makeApprove",
  async (account: string) => {
    await approveTicketContract(account);
  }
);
export const purchaseProfileTicketAction = createAsyncThunk(
  "profile/makePurchase",
  async (account: string) => {
    await purchaseTicket(account);
  }
);

export const getUserTicketId = createAsyncThunk(
  "profile/getUserTicketId",
  async (account: string) => {
    const data = await userTicketId(account);

    return Number(data);
  }
);

export const getUserBoughtTicket = createAsyncThunk(
  "profile/getUserTicketId",
  async (account: string) => {
    const data: boolean = await boughtTicket(account);

    return data;
  }
);

export const signProfile = createAsyncThunk(
  "profile/signProfile",
  async ({ signMsg }: { signMsg: any }) => {
    const signature = await signMessage(signMsg);

    return signature;
  }
);

export const registerProfile = createAsyncThunk(
  "profile/registerProfile",
  async ({ signature, signMsg }: { signature: string; signMsg: any }) => {
    const response = await axios.post(`${SERVER_URL}/profile`, {
      signature,
      signMsg,
    });

    return response.data.name;
  }
);

export const getProfile = createAsyncThunk(
  "profile/getProfile",
  async (name: string) => {
    const response = await axios.get(`${SERVER_URL}/profile/name/${name}`);

    return response.data;
  }
);

export const getMyProfile = createAsyncThunk(
  "profile/getMyProfile",
  async (name: string) => {
    const response = await axios.get(`${SERVER_URL}/profile/name/${name}`);

    return response.data;
  }
);

export const getMyInventoryItems = createAsyncThunk(
  "profile/getMyInventoryItems",
  async ({ userId }: { userId: number }) => {
    const response = await axios.get(
      `${SERVER_URL}/profile/inventory/${userId}`
    );

    return response.data;
  }
);

export const getLatestProfiles = createAsyncThunk(
  "profile/getLatestProfiles",
  async () => {
    const response = await axios.get(`${SERVER_URL}/profile/latest`);

    return response.data;
  }
);

export const getProfileNames = createAsyncThunk(
  "profile/getProfileNames",
  async () => {
    const response = await axios.get(`${SERVER_URL}/profile/names`);

    return response.data;
  }
);

export const getInventoryItem = createAsyncThunk(
  "profile/getInventoryItem",
  async ({ userId }: { userId: number }) => {
    const response = await axios.get(
      `${SERVER_URL}/profile/inventory/${userId}`
    );

    return response.data;
  }
);

export const signData = createAsyncThunk(
  "profile/signData",
  async ({ signMsg }: { signMsg: any }) => {
    const signature = await signMessage(signMsg);

    return signature;
  }
);

export const updateProfileDescription = createAsyncThunk(
  "profile/updateProfileDescription",
  async ({
    userId,
    signMsg,
    signature,
  }: {
    userId: number;
    signMsg: any;
    signature: string;
  }) => {
    const response = await axios.put(
      `${SERVER_URL}/profile/description/${userId}`,
      {
        signMsg,
        signature,
      }
    );

    return response.data;
  }
);

export const updateProfileDisplaySetting = createAsyncThunk(
  "profile/updateProfileDisplaySetting",
  async ({
    userId,
    signMsg,
    signature,
  }: {
    userId: number;
    signMsg: any;
    signature: string;
  }) => {
    const response = await axios.put(
      `${SERVER_URL}/profile/inventoryDisplay/${userId}`,
      {
        signMsg,
        signature,
      }
    );

    return response.data;
  }
);

export const getInventoryItemStocks = createAsyncThunk(
  "profile/getInventoryItemStocks",
  async () => {
    const categories = await getStockCounts();

    return categories;
  }
);

export const transferInventoryItem = createAsyncThunk(
  "profile/transferInventoryItem",
  async ({
    to,
    account,
    itemId,
  }: {
    to: string;
    account: string;
    itemId: number;
  }) => {
    await transferItem(account, to, itemId);
  }
);

export const transferNFT = createAsyncThunk(
  "profile/transferNFT",
  async ({
    to,
    account,
    amount,
    contractAddress,
  }: {
    to: string;
    account: string;
    amount: number;
    contractAddress: string;
  }) => {
    await transferErc1155(account, to, amount, contractAddress);
  }
);

export const wrapOGCrateAction = createAsyncThunk(
  "profile/wrapOGCrateAction",
  async ({ account }: { account: string }) => {
    await wrapOGCrate(account);
  }
);

export const unwrapOGCrateAction = createAsyncThunk(
  "profile/unwrapOGCrateAction",
  async ({ account, itemId }: { account: string; itemId: number }) => {
    await unwrapOGCrate(account, itemId);
  }
);

export const wrapOGNFTAction = createAsyncThunk(
  "profile/wrapOGNFTAction",
  async ({ account }: { account: string }) => {
    await wrapOGNFT(account);
  }
);

export const unwrapOGNFTAction = createAsyncThunk(
  "profile/unwrapOGNFTAction",
  async ({ account, itemId }: { account: string; itemId: number }) => {
    await unwrapOGNFT(account, itemId);
  }
);

export const unwrapKeyItemAction = createAsyncThunk(
  "profile/unwrapKeyItemAction",
  async ({ account, itemId }: { account: string; itemId: number }) => {
    await unwrapKeyItem(account, itemId);
  }
);

export const unwrapMafiaItemAction = createAsyncThunk(
  "profile/unwrapMafiaItemAction",
  async ({ account, itemId }: { account: string; itemId: number }) => {
    await unwrapMafiaItem(account, itemId);
  }
);

export const unwrapOGNFTItemAction = createAsyncThunk(
  "profile/unwrapOGNFTItemAction",
  async ({ account, itemId }: { account: string; itemId: number }) => {
    await unwrapOGNFTItem(account, itemId);
  }
);

export const approveAllCrate = createAsyncThunk(
  "profile/approveAllCrate",
  async ({ spender, account }: { spender: string; account: string }) => {
    await setCrateApprovalForAll(spender, true, account);
  }
);

export const approveAllOGNFT = createAsyncThunk(
  "profile/approveAllOGNFT",
  async ({ address, account }: { address: string; account: string }) => {
    await setOGNFTApprovalForAll(address, true, account);
  }
);

export const buyPremiumAction = createAsyncThunk(
  "profile/buyPremiumAction",
  async ({ account }: { account: string }) => {
    await buyPremium(account);
  }
);

export const claimKeysAction = createAsyncThunk(
  "profile/claimKeys",
  async ({ account }: { account: string }) => {
    const data = await claimKeys(account);

    return data.transactionHash;
  }
);

export const profileSlice = createSlice({
  name: "profile",
  initialState,
  reducers: {
    setIsCreating: (state, { payload }) => {
      state.isCreatingProfile = payload;
    },

    clearMyProfile: (state) => {
      state.myProfile = {};
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getUserPremiumInfo.pending, (state) => {});
    builder.addCase(getUserPremiumInfo.fulfilled, (state, { payload }) => {
      state.premiumInfo = {
        userInfo: payload.userInfo,
        lastClaimedAt: payload.lastClaimedAt,
      };
    });
    builder.addCase(getUserPremiumInfo.rejected, (state, { error }) => {
      console.log(error);
    });

    builder.addCase(getCrateBalance.pending, (state) => {});
    builder.addCase(getCrateBalance.fulfilled, (state, { payload }) => {
      state.crateBalance = payload;
    });
    builder.addCase(getCrateBalance.rejected, (state, { error }) => {});

    builder.addCase(getOgNFTBalance.pending, (state) => {});
    builder.addCase(getOgNFTBalance.fulfilled, (state, { payload }) => {
      state.ogNFTBalance = payload;
    });
    builder.addCase(getOgNFTBalance.rejected, (state, { error }) => {});

    builder.addCase(approveProfileTicketAction.pending, (state) => {
      state.isCreatingProfile = true;
    });
    builder.addCase(
      approveProfileTicketAction.fulfilled,
      (state, { payload }) => {}
    );
    builder.addCase(approveProfileTicketAction.rejected, (state, { error }) => {
      state.isCreatingProfile = false;
    });

    builder.addCase(purchaseProfileTicketAction.pending, (state) => {
      state.isCreatingProfile = true;
    });
    builder.addCase(
      purchaseProfileTicketAction.fulfilled,
      (state, { payload }) => {}
    );
    builder.addCase(
      purchaseProfileTicketAction.rejected,
      (state, { error }) => {
        state.isCreatingProfile = false;
      }
    );

    builder.addCase(getUserTicketId.pending, (state) => {});
    builder.addCase(getUserTicketId.fulfilled, (state, { payload }) => {});
    builder.addCase(getUserTicketId.rejected, (state, { error }) => {});

    builder.addCase(signProfile.pending, (state) => {
      state.isCreatingProfile = true;
    });
    builder.addCase(signProfile.fulfilled, (state, { payload }) => {
      state.isCreatingProfile = false;
    });
    builder.addCase(signProfile.rejected, (state, { error }) => {
      state.isCreatingProfile = false;
    });

    builder.addCase(registerProfile.pending, (state) => {});
    builder.addCase(registerProfile.fulfilled, (state, { payload }) => {
      state.isCreatingProfile = false;
    });
    builder.addCase(registerProfile.rejected, (state, { error }) => {
      state.isCreatingProfile = false;
    });

    builder.addCase(getProfile.pending, (state) => {
      state.isLoadingProfile = true;
    });
    builder.addCase(getProfile.fulfilled, (state, { payload }) => {
      state.isLoadingProfile = false;
      state.profile = {
        ...payload,
        createdDate: payload.date_created,
        inventoryVisibility: payload.inventory_display,
      } as UserProfile;
    });
    builder.addCase(getProfile.rejected, (state, { error }) => {
      state.isLoadingProfile = false;
      toastError(Errors.GLOBAL.PROFILE.GETTING_FAILED);
    });

    builder.addCase(getMyProfile.pending, (state) => {
      state.isLoadingMyProfile = true;
    });
    builder.addCase(getMyProfile.fulfilled, (state, { payload }) => {
      state.isLoadingMyProfile = false;
      state.myProfile = {
        ...payload,
        createdDate: payload.date_created,
      } as UserProfile;
    });
    builder.addCase(getMyProfile.rejected, (state, { error }) => {
      state.isLoadingMyProfile = false;
      toastError(Errors.GLOBAL.PROFILE.GETTING_FAILED);
    });

    builder.addCase(getMyInventoryItems.pending, (state) => {
      state.isLoadingMyInventoryItems = true;
    });
    builder.addCase(getMyInventoryItems.fulfilled, (state, { payload }) => {
      state.myInventoryItems = payload.map((item: any, index: number) => {
        return {
          itemId: item.id,
          categoryId: item.itemDetail.category_id,
          typeId: item.itemDetail.type_id,
          cityId: item.city,
        };
      });

      state.isLoadingMyInventoryItems = false;
    });
    builder.addCase(getMyInventoryItems.rejected, (state, { error }) => {
      state.isLoadingMyInventoryItems = true;
    });

    builder.addCase(getLatestProfiles.pending, (state) => {
      state.isLoadingLatestProfiles = true;
    });
    builder.addCase(getLatestProfiles.fulfilled, (state, { payload }) => {
      state.isLoadingLatestProfiles = false;
      state.latestProfiles = payload;
    });
    builder.addCase(getLatestProfiles.rejected, (state, { error }) => {
      state.isLoadingLatestProfiles = false;
    });

    builder.addCase(getProfileNames.pending, (state) => {
      state.isLoadingProfileNames = true;
    });
    builder.addCase(getProfileNames.fulfilled, (state, { payload }) => {
      state.profileNames = payload;
      state.isLoadingProfileNames = false;
    });
    builder.addCase(getProfileNames.rejected, (state, { error }) => {
      state.isLoadingProfileNames = false;
    });

    builder.addCase(getInventoryItem.pending, (state) => {
      state.isLoadingInventoryItems = true;
    });
    builder.addCase(getInventoryItem.fulfilled, (state, { payload }) => {
      state.inventoryItems = payload.map((item: any, index: number) => {
        return {
          itemId: item.id,
          categoryId: item.itemDetail.category_id,
          typeId: item.itemDetail.type_id,
          cityId: item.city,
        };
      });

      state.isLoadingInventoryItems = false;
    });
    builder.addCase(getInventoryItem.rejected, (state, { error }) => {
      state.isLoadingInventoryItems = true;
    });

    builder.addCase(updateProfileDescription.pending, (state) => {
      state.isUpdatingProfileDescription = true;
    });
    builder.addCase(
      updateProfileDescription.fulfilled,
      (state, { payload }) => {
        state.isUpdatingProfileDescription = false;
      }
    );
    builder.addCase(updateProfileDescription.rejected, (state, { error }) => {
      state.isUpdatingProfileDescription = false;
    });

    builder.addCase(updateProfileDisplaySetting.pending, (state) => {
      state.isUpdatingProfileDisplaySetting = true;
    });
    builder.addCase(
      updateProfileDisplaySetting.fulfilled,
      (state, { payload }) => {
        state.isUpdatingProfileDisplaySetting = false;
      }
    );
    builder.addCase(
      updateProfileDisplaySetting.rejected,
      (state, { error }) => {
        state.isUpdatingProfileDisplaySetting = false;
      }
    );

    builder.addCase(getInventoryItemStocks.pending, (state) => {
      state.isLoadingInventoryItemStocks = true;
    });
    builder.addCase(getInventoryItemStocks.fulfilled, (state, { payload }) => {
      state.inventoryItemStocks = payload;
      state.isLoadingInventoryItemStocks = false;
    });
    builder.addCase(getInventoryItemStocks.rejected, (state, { error }) => {
      state.isLoadingInventoryItemStocks = false;
    });

    builder.addCase(transferInventoryItem.pending, (state) => {
      state.isTransferringItem = true;
    });
    builder.addCase(transferInventoryItem.fulfilled, (state, { payload }) => {
      state.isTransferringItem = false;
    });
    builder.addCase(transferInventoryItem.rejected, (state, { error }) => {
      state.isTransferringItem = false;
    });

    builder.addCase(transferNFT.pending, (state) => {
      state.isTransferringCrate = true;
    });
    builder.addCase(transferNFT.fulfilled, (state, { payload }) => {
      state.isTransferringCrate = false;
    });
    builder.addCase(transferNFT.rejected, (state, { error }) => {
      state.isTransferringCrate = false;
    });

    builder.addCase(wrapOGCrateAction.pending, (state) => {
      state.isWrappingOgCrate = true;
    });
    builder.addCase(wrapOGCrateAction.fulfilled, (state, { payload }) => {
      state.isWrappingOgCrate = false;
    });
    builder.addCase(wrapOGCrateAction.rejected, (state, { error }) => {
      state.isWrappingOgCrate = false;
    });

    builder.addCase(unwrapOGCrateAction.pending, (state) => {
      state.isUnwrappingOgCrate = true;
    });
    builder.addCase(unwrapOGCrateAction.fulfilled, (state, { payload }) => {
      state.isUnwrappingOgCrate = false;
    });
    builder.addCase(unwrapOGCrateAction.rejected, (state, { error }) => {
      state.isUnwrappingOgCrate = false;
    });

    builder.addCase(wrapOGNFTAction.pending, (state) => {
      state.isWrappingOGNFTAction = true;
    });
    builder.addCase(wrapOGNFTAction.fulfilled, (state, { payload }) => {
      state.isWrappingOGNFTAction = false;
    });
    builder.addCase(wrapOGNFTAction.rejected, (state, { error }) => {
      state.isWrappingOGNFTAction = false;
    });

    builder.addCase(unwrapOGNFTAction.pending, (state) => {
      state.isUnwrappingOGNFTAction = true;
    });
    builder.addCase(unwrapOGNFTAction.fulfilled, (state, { payload }) => {
      state.isUnwrappingOGNFTAction = false;
    });
    builder.addCase(unwrapOGNFTAction.rejected, (state, { error }) => {
      state.isUnwrappingOGNFTAction = false;
    });

    builder.addCase(unwrapKeyItemAction.pending, (state) => {
      state.isUnwrappingKeyItem = true;
    });
    builder.addCase(unwrapKeyItemAction.fulfilled, (state, { payload }) => {
      state.isUnwrappingKeyItem = false;
    });
    builder.addCase(unwrapKeyItemAction.rejected, (state, { error }) => {
      state.isUnwrappingKeyItem = false;
    });

    builder.addCase(unwrapMafiaItemAction.pending, (state) => {
      state.isUnwrappingMafiaItem = true;
    });
    builder.addCase(unwrapMafiaItemAction.fulfilled, (state, { payload }) => {
      state.isUnwrappingMafiaItem = false;
    });
    builder.addCase(unwrapMafiaItemAction.rejected, (state, { error }) => {
      state.isUnwrappingMafiaItem = false;
    });

    builder.addCase(unwrapOGNFTItemAction.pending, (state) => {
      state.isUnwrappingOGNFTItem = true;
    });
    builder.addCase(unwrapOGNFTItemAction.fulfilled, (state, { payload }) => {
      state.isUnwrappingOGNFTItem = false;
    });
    builder.addCase(unwrapOGNFTItemAction.rejected, (state, { error }) => {
      state.isUnwrappingOGNFTItem = false;
    });

    builder.addCase(approveAllCrate.pending, (state) => {
      state.isApprovingCrate = true;
    });
    builder.addCase(approveAllCrate.fulfilled, (state, { payload }) => {
      state.isApprovingCrate = false;
    });
    builder.addCase(approveAllCrate.rejected, (state, { error }) => {
      state.isApprovingCrate = false;
    });

    builder.addCase(approveAllOGNFT.pending, (state) => {
      state.isApprovingOGNFT = true;
    });
    builder.addCase(approveAllOGNFT.fulfilled, (state, { payload }) => {
      state.isApprovingOGNFT = false;
    });
    builder.addCase(approveAllOGNFT.rejected, (state, { error }) => {
      state.isApprovingOGNFT = false;
    });

    builder.addCase(buyPremiumAction.pending, (state) => {
      state.isBuyingPremium = true;
    });
    builder.addCase(buyPremiumAction.fulfilled, (state, { payload }) => {
      state.isBuyingPremium = false;
    });
    builder.addCase(buyPremiumAction.rejected, (state, { error }) => {
      state.isBuyingPremium = false;
    });

    builder.addCase(signData.pending, (state) => {
      state.isSigningProfileData = true;
    });
    builder.addCase(signData.fulfilled, (state, { payload }) => {
      state.isSigningProfileData = false;
    });
    builder.addCase(signData.rejected, (state, { error }) => {
      state.isSigningProfileData = false;
    });

    builder.addCase(claimKeysAction.pending, (state) => {
      state.isClaimingKeys = true;
    });
    builder.addCase(claimKeysAction.fulfilled, (state, { payload }) => {
      state.isClaimingKeys = false;
    });
    builder.addCase(claimKeysAction.rejected, (state, { error }) => {
      state.isClaimingKeys = false;
    });
  },
});

export const { setIsCreating, clearMyProfile } = profileSlice.actions;

export default profileSlice.reducer;
