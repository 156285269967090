import { useGSAP } from "@gsap/react";
import CloseIcon from "@mui/icons-material/Close";
import { Box, Button, Dialog } from "@mui/material";
import clsx from "clsx";
import gsap from "gsap";
import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";

import {
  ItemCategoryInfoList,
  PREMIUM_KEY_CLAIM_COUNT,
} from "../../constants/const";
import { ItemCategory } from "../../constants/enum/enum";

import crateIcon from "../../assets/imgs/inventory/crate.png";

import config from "../../config/config";

import useStyles from "./index.styles";

gsap.registerPlugin(useGSAP);

interface ProfileClaimKeyPopupProps {
  handleClaimKey: () => void;
  onEndAnimation: () => void;
  txHash: string;
  claimKeyCount: number;
  remainClaimCount: number;
  openProfileClaimKeyPopup: boolean;
  setOpenProfileClaimKeyPopup: React.Dispatch<React.SetStateAction<boolean>>;
}

const ProfileClaimKeyPopup: React.FC<ProfileClaimKeyPopupProps> = ({
  handleClaimKey,
  onEndAnimation,
  txHash,
  claimKeyCount,
  remainClaimCount,
  openProfileClaimKeyPopup,
  setOpenProfileClaimKeyPopup,
}) => {
  const { classes } = useStyles();

  // State to manage the currently selected index, speed, and cycles
  const [currentIndex, setCurrentIndex] = useState(-1);
  const [speed, setSpeed] = useState(15); // initial speed in milliseconds
  const [cycles, setCycles] = useState(0); // tracks full cycles through the prize list
  const [animationStarted, setAnimationStarted] = useState(false);
  const [direction, setDirection] = useState(1); // 1 for increasing, -1 for decreasing

  const maxIndex = PREMIUM_KEY_CLAIM_COUNT.length - 1;
  const totalCycles = 10; // total number of cycles before stopping

  const tl = useRef<gsap.core.Timeline>();
  const winContentRef = useRef<HTMLElement>();

  useEffect(() => {
    if (!openProfileClaimKeyPopup) return;

    const timer = setTimeout(() => {
      setAnimationStarted(true);
    }, 500);

    return () => {
      clearTimeout(timer);
    };
  }, [openProfileClaimKeyPopup]);

  useEffect(() => {
    if (!animationStarted) return;

    const timer = setInterval(() => {
      setCurrentIndex((prevIndex) => {
        const nextIndex = prevIndex + direction;

        // Check if we need to reverse the direction
        if (nextIndex >= maxIndex) {
          setDirection(-1); // Switch to decreasing
          setCycles((prevCycles) => prevCycles + 1); // Increment cycle count
          return maxIndex; // Ensure it stays within bounds
        } else if (nextIndex <= 0) {
          setDirection(1); // Switch to increasing
          setCycles((prevCycles) => prevCycles + 1); // Increment cycle count
          return 0; // Ensure it stays within bounds
        }

        return nextIndex;
      });

      // Gradually slow down
      setSpeed((prevSpeed) => prevSpeed * 1.033);
    }, speed);

    // Stop condition based on cycles and specific index
    if (
      cycles > totalCycles &&
      currentIndex === PREMIUM_KEY_CLAIM_COUNT.indexOf(claimKeyCount)
    ) {
      clearInterval(timer);
      initPopup();
      onEndAnimation();

      startWinAnimation();
    }

    console.log(currentIndex, claimKeyCount);

    return () => clearInterval(timer);
  }, [animationStarted, currentIndex, speed, cycles]);

  const initPopup = () => {
    setAnimationStarted(false);
    setSpeed(15);
    setCycles(0);
  };

  const handleClose = () => {
    setOpenProfileClaimKeyPopup(false);
  };

  const handleClaimKeyAgain = () => {
    handleClose();
    handleClaimKey();
  };

  const startWinAnimation = () => {
    tl.current = gsap.timeline();
    tl.current.to(".prizeListItem", { opacity: 0, delay: 1, stagger: 0.1 });
    if (!winContentRef.current) return;

    tl.current.fromTo(
      winContentRef.current,
      { opacity: 0, scale: 0 },
      { opacity: 1, scale: 1, ease: "bounce" }
    );
  };

  return (
    <Dialog
      open={openProfileClaimKeyPopup}
      onClose={handleClose}
      className={classes.body}
    >
      <Box className={classes.modalContent}>
        <Box className={classes.closeIconBody}>
          <Button onClick={handleClose}>
            <CloseIcon htmlColor="gray" />
          </Button>
        </Box>

        <Box className={classes.contentBody}>
          <Box className={classes.header}>
            <Box component="img" src={crateIcon}></Box>
          </Box>

          <Box className={classes.container}>
            {PREMIUM_KEY_CLAIM_COUNT.map((keyCount, index) => {
              const icon = ItemCategoryInfoList[ItemCategory.KEY].icon;
              return (
                <Box
                  key={index}
                  className={
                    index === currentIndex
                      ? clsx(
                          classes.prizeItem,
                          classes.selected,
                          "prizeListItem",
                          "selected"
                        )
                      : clsx(classes.prizeItem, "prizeListItem")
                  }
                >
                  <Box>{keyCount} keys</Box>

                  <Box
                    component="img"
                    src={`/assets/imgs/inventory/${icon}`}
                  ></Box>
                </Box>
              );
            })}
          </Box>

          <Box className={classes.winContent} ref={winContentRef}>
            <Box className={classes.grateText}>Congratulations!</Box>

            <Box className={classes.prizeName}>{claimKeyCount} Keys</Box>

            <Box
              className={classes.prizeIcon}
              component="img"
              src={`/assets/imgs/inventory/${
                ItemCategoryInfoList[ItemCategory.KEY].icon
              }`}
            ></Box>

            <Box className={classes.description}>
              Has Been added to your inventory
            </Box>
            <Link
              className={classes.viewTxLink}
              to={`${config.explorerUrl}tx/${txHash}`}
              target="_blank"
            >
              View Transaction
            </Link>

            {remainClaimCount > 0 ? (
              <Button
                className={classes.openCreateButton}
                onClick={handleClaimKeyAgain}
              >
                Claim Key Again
              </Button>
            ) : (
              <Button
                className={classes.openCreateButton}
                onClick={handleClose}
              >
                Close
              </Button>
            )}
          </Box>
        </Box>
      </Box>
    </Dialog>
  );
};

export default ProfileClaimKeyPopup;
